import React, { useEffect, useContext } from "react"
import Header from "./Header"
import { Helmet } from "react-helmet"
import Footer from "./Footer"
import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav/src/context/NavContext"

const Layout = ({ children }) => {
  const { navData } = useContext(NavContext) || { navData: {} }

  return (
    <div className="flex flex-col min-h-screen">
      <Header navigation={navData?.mainNavigation?.navigation} />
      <Helmet
        htmlAttributes={{ lang: "de" }}
        bodyAttributes={{
          class: "theme-default font-body",
        }}
      />

      <main className="z-10 flex-1 min-h-screen bg-white shadow-md">
        {children}
      </main>
      <Footer navigation={navData?.footer?.navigation} />
    </div>
  )
}

export default Layout
