import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

const startYear = 2022
const currentYear = new Date().getFullYear()

const Footer = ({ footerRef, navigation }) => {
  return (
    <footer
      ref={footerRef}
      className="bottom-0 w-full py-8 bg-white md:sticky md:z-0"
    >
      <section className="px-8">
        <div className="max-w-screen-lg mx-auto mb-8">
          <div className="flex flex-row flex-wrap -mb-8 -mx-grid">
            {navigation?.map((nav_col, colIndex) => (
              <div
                className="w-full mb-8 px-grid sm:w-1/2 md:w-1/3"
                key={colIndex}
              >
                {nav_col?.nav_items?.map((item, navItemIndex) => (
                  <React.Fragment key={navItemIndex}>
                    {item.component === "nav_richtext" ? (
                      <Richtext text={item.text} />
                    ) : (
                      <Link link={item.link}>{item.title}</Link>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="px-8">
        <div className="max-w-screen-lg mx-auto">
          <div className="flex flex-row flex-wrap py-4 -mx-grid">
            <div className="w-full px-grid sm:text-right sm:w-1/2">
              <Link
                className="py-2 text-primary hover:text-primary-hover hover:underline"
                link="/impressum"
              >
                Impressum
              </Link>
              <Link
                className="py-2 ml-8 text-primary hover:text-primary-hover hover:underline"
                link="/datenschutz"
              >
                Datenschutz
              </Link>
            </div>
            <div className="w-full px-grid sm:w-1/2 sm:order-first">
              <span>© {currentYear} ita vero GmbH</span>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
