import React, { useState, useEffect } from "react"
import Obfuscate from "react-obfuscate"
import logo from "../../images/logo.jpg"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faTimes,
  faPhone,
  faAt,
  faCaretDown,
} from "@fortawesome/pro-solid-svg-icons"

import useMediaQuery from "../../hooks/useMediaQuery"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const Header = ({ navigation }) => {
  const [dropdownState, setDropdownState] = useState(null)
  const [menuState, setMenuState] = useState(null)
  const menuClickHandler = (state) => {
    if (menuState === state) {
      setMenuState(null)
    } else {
      setMenuState(state)
    }
  }

  const dropdownHandler = (event, state) => {
    event.preventDefault()
    if (dropdownState === state) {
      setDropdownState(null)
    } else {
      setDropdownState(state)
    }
  }

  const doBlur = useMediaQuery("(min-width: 1024px)")

  const blurHandler = () => {
    if (doBlur) {
      setDropdownState(null)
    } else {
    }
  }

  useEffect(() => {
    if (window && document) {
      gsap.registerPlugin(ScrollTrigger)
      let mainHeader = document.querySelector(".header-main")
      ScrollTrigger.create({
        start: "top -32",
        end: 99999,
        onEnter: () => {
          mainHeader.classList.remove("header-main-top")
          mainHeader.classList.add("header-main-scrolled")
        },
        onLeaveBack: () => {
          mainHeader.classList.remove("header-main-scrolled")
          mainHeader.classList.add("header-main-top")
        },
      })
    }
  }, [])

  return (
    <header className="header-main header-main-top w-full z-[100]">
      <div className="w-full px-8 text-sm text-white bg-opacity-75 bg-primary sm:text-base">
        <div className="max-w-screen-lg mx-auto text-sm leading-relaxed text-right">
          <Obfuscate
            className="inline-block leading-8 hover:underline"
            email="info@itavero.de"
          >
            <FontAwesomeIcon
              icon={faAt}
              size="1x"
              className="inline-block h-[1em] mr-1"
            />
          </Obfuscate>
          <Obfuscate
            className="inline-block mr-8 leading-8 hover:underline"
            email="info@itavero.de"
          />
          <Link
            className="inline-block hover:underline"
            link="tel:496101655900"
          >
            <FontAwesomeIcon
              icon={faPhone}
              size="1x"
              className="inline-block h-[1em] mr-1"
            />
            +49 6101 65590-0
          </Link>
        </div>
      </div>
      <div className="relative w-full px-8 bg-white shadow-md">
        <div className="max-w-screen-lg py-2 mx-auto ">
          <div className="flex flex-row ">
            {/* logo */}
            <Link className="block w-[240px] -mb-1" link="/">
              <Image
                forceLoad={true}
                className="block w-auto h-9"
                image={{ filename: logo, alt: "ita vero", title: "ita vero" }}
              />
              <span className="inline-block text-[10px] italic mt-1 font-bold min-w-[240px]">
                Ein Unternehmen der HassiaGruppe
              </span>
            </Link>
            {/* burger-Menu */}
            <button
              tabIndex={"open"}
              className="h-8 my-auto ml-auto -mr-2 text-gray-700 cursor-pointer lg:hidden"
              onClick={() => menuClickHandler("open")}
            >
              <span className="inline-block h-8 px-2">
                {menuState === "open" ? (
                  <FontAwesomeIcon icon={faTimes} size="2x" className="" />
                ) : (
                  <FontAwesomeIcon icon={faBars} size="2x" className="" />
                )}
              </span>
            </button>
            {/* navigation */}
            <nav
              className={`absolute z-[140] left-0 right-0 top-full flex-1 ${
                menuState === "open" ? "visible" : "invisible lg:visible"
              } shadow-md lg:shadow-none border-t border-gray-300 lg:border-t-0 font-bold leading-8 bg-white px-6 pb-2 lg:pb-0 lg:bg-transparent lg:mt-0 lg:ml-4 lg:px-0 lg:static h-100 w-full lg:-mx-4`}
            >
              <ul className="flex flex-col flex-wrap justify-end w-full lg:flex-row">
                {navigation?.map((item, navIndex) => (
                  <li
                    key={navIndex}
                    className="relative px-2 mt-1 lg:px-4 focus:outline-none"
                    onBlur={() => blurHandler()}
                    tabIndex="0"
                  >
                    {item?.sub_nav?.length === 0 ? (
                      <Link
                        className="relative inline-block py-2 group"
                        link={item.link}
                      >
                        <span
                          className={`absolute block left-0 bottom-3 h-[2px] ${
                            window !== undefined &&
                            window?.location?.href?.indexOf(
                              item.link.cached_url
                            ) !== -1
                              ? "w-full bg-primary"
                              : "w-0 group-hover:w-full bg-transparent group-hover:bg-primary"
                          } transition-[width] duration-300`}
                        />
                        {item.title}
                        <span
                          className={`absolute block right-0 bottom-3 h-[2px] ${
                            window !== undefined &&
                            window?.location?.href?.indexOf(
                              item.link.cached_url
                            ) !== -1
                              ? "w-full bg-transparent"
                              : "w-0 group-hover:w-full bg-primary group-hover:bg-transparent"
                          } transition-[width] duration-300`}
                        />
                      </Link>
                    ) : (
                      <>
                        <Link
                          link={item.link}
                          className="relative inline-block py-2 group"
                          onClick={(e) => dropdownHandler(e, navIndex)}
                        >
                          <span
                            className={`absolute block left-0 bottom-3 h-[2px] ${
                              window !== undefined &&
                              window?.location?.href.indexOf(
                                item.link.cached_url
                              ) !== -1
                                ? "w-full bg-primary"
                                : "w-0 group-hover:w-full bg-transparent group-hover:bg-primary"
                            } transition-[width] duration-300 ${
                              dropdownState === navIndex
                                ? "w-full bg-primary"
                                : ""
                            }`}
                          />
                          {item.title}
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            size="1x"
                            className={`ml-2 transition-transform duration-300 ${
                              dropdownState === navIndex
                                ? "transform -rotate-180"
                                : ""
                            }`}
                          />
                          <span
                            className={`absolute block right-0 bottom-3 h-[2px] ${
                              window !== undefined &&
                              window?.location?.href.indexOf(
                                item.link.cached_url
                              ) !== -1
                                ? "w-full bg-transparent"
                                : "w-0 group-hover:w-full bg-primary group-hover:bg-transparent"
                            } transition-[width] duration-300 ${
                              dropdownState === navIndex
                                ? "w-full bg-transparent"
                                : "w-0"
                            }`}
                          />
                        </Link>
                        <ul
                          className={`lg:absolute min-w-full lg:shadow-md left-0 top-full bg-white px-grid transform transition-all origin-left duration-300 ${
                            dropdownState === navIndex
                              ? "lg:visible opacity-100"
                              : "hidden lg:block lg:invisible -translate-x-4 opacity-0"
                          }`}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {item?.sub_nav?.map((subitem, index) => (
                            <li key={index}>
                              <Link
                                className="relative block py-2 leading-tight hover:text-primary"
                                link={subitem.link}
                              >
                                {subitem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
