import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/pro-solid-svg-icons"
// import { far } from "@fortawesome/pro-regular-svg-icons"
// import { fab } from "@fortawesome/free-brands-svg-icons"
// import { fal } from "@fortawesome/pro-light-svg-icons"
// import { fat } from "@fortawesome/pro-thin-svg-icons"
// import { fad } from "@fortawesome/pro-duotone-svg-icons"

library.add(fas)
// library.add(far)
// library.add(fab)
// library.add(fal)
// library.add(fat)
// library.add(fad)
