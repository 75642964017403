import { useState, useLayoutEffect } from "react"

const useMediaQuery = (mediaQuery) => {
  const [matches, setMatches] = useState(
    () => typeof window !== "undefined" && window.matchMedia(mediaQuery).matches
  )

  useLayoutEffect(() => {
    const media = window.matchMedia(mediaQuery)
    media.matches !== matches && setMatches(media.matches)
  }, [matches, mediaQuery])
  return matches
}

export default useMediaQuery
