import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../../../../utils/font-awesome"

const Icon = ({ faName, icon, type = "fas", size, className }) => {
  const [iconName, setIconName] = useState("")
  const [iconType, setIconType] = useState("")

  let definedType =
    icon.type !== "fab" && type && type.length > 0 ? type : icon.type

  useEffect(() => {
    icon &&
      !faName &&
      (setIconName(icon.icon.replace("fa-", "")), setIconType(definedType))
    faName && setIconName(faName.replace("fa-", ""))
  })

  return (
    <>
      {iconName !== "" ? (
        <FontAwesomeIcon
          icon={[iconType, iconName]}
          size={size}
          className={className}
        />
      ) : null}
    </>
  )
}

export default Icon
