import React from "react"

const Headline = ({
  headline,
  headlineLevel = "h2",
  tagName,
  className = "relative inline-block",
}) => {
  const HTag =
    tagName && tagName.length > 0
      ? tagName
      : headlineLevel && headlineLevel.length > 0
      ? headlineLevel
      : "h2"
  return (
    <HTag
      className={`headline headline-${headlineLevel} ${
        className ? className : ""
      }`}
      dangerouslySetInnerHTML={{
        __html: headline.replace("|", "<br />"),
      }}
    />
  )
}
export default Headline
